import { Form, Formik } from "formik";
import { useState, useRef, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { authContext } from "../../../../store/context/authContext";
import BasicInfoForm from "./BasicInfoForm";
import { generalCreate, generalGet } from "../../../../API/api";
import ConditionsForm from "./ConditionsForm";
import BalloonsForm from "./BalloonsForm";
import Button from "../../../../components/buttons/Button";
import { IProductData, IWorkSector } from "../../types/interfaces";
import { scrollToError } from "../../../../utils/HelperFunctions";

const CreateProductForm = ({ productData, workSectors, id }: {
    productData: IProductData,
    id?: string,
    workSectors: IWorkSector,
}) => {
    const { catchError } = useContext(authContext)
    const navigate = useNavigate()
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();
    const [serviceProviders, setServiceProviders] = useState([]);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const sp = urlParams?.get('sp');
    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["service providers"],
        queryFn: () => generalGet("/service-providers"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = data?.data.data
        if (isSuccess) {
            setServiceProviders(reqData?.map((service: any) => {
                return { label: service.name[i18n.language], value: service.id.toString() }
            }))
        }
    }, [isSuccess, data])

    const validationSchema = Yup.object().shape({
        service_provider_id: Yup.string().required(t('required')),
        minimum_age: Yup.string().required(t('required')),
        maximum_age: Yup.string().required(t('required')),
        duration_to: Yup.mixed().required(t('required')),
        down_payment: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great'))
            .max(100, t('must_min')),
        profit_rate: Yup.array()
            .of(
                Yup.number()
                    .typeError(t('must_be_a_number'))
                    .required(t('required'))
                    .min(0, t('min_value'))
                    .max(100, t('max_value'))
            ),
        admin_fee: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great'))
            .max(100, t('must_min')),
        insurance_fee: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great'))
            .max(100, t('must_min')),
        disclaimer: Yup.object().shape(
            {
                ar: Yup.string().test("disclaimer-required", t("required"), function (value) {
                    const { en } = this.parent;
                    if (en) {
                        return !!value;
                    }
                    return true;
                }),
                en: Yup.string().test("disclaimer-required", t("required"), function (value) {
                    const { ar } = this.parent;
                    if (ar) {
                        return !!value;
                    }
                    return true;
                }),
            }
        ),
        conditions: Yup.array().of(
            Yup.object().shape(
                {
                    work_sectors: Yup.array()
                        .of(Yup.string().required(t("required")))
                        .min(1, t("required")),
                    minimum_salary: Yup.string().required(t("required")),
                    service_period: Yup.string().required(t("required")),
                    nationality: Yup.string().required(t("required")),
                    salary_transferred_to: Yup.string().required(t("required"))
                }
            )
        ),
        balloon_conditions: Yup.array().of(
            Yup.object().shape(
                {
                    vehicle_model_id: Yup.string().required("required"),
                    salary_transferred_to: Yup.string().required("required"),
                    balloon_percentage: Yup.number()
                        .typeError(t('must_be_a_number'))
                        .required(t('required'))
                        .min(0, t('must_great'))
                        .max(100, t('must_min'))
                }
            )
        ),
    });

    const initialValues = productData ? { ...productData, conditions: productData?.conditions, disclaimer: { en: "", ar: "" } } : {
        duration_from: 1,
        service_provider_id: sp ? sp : "",
        maximum_age: "",
        minimum_age: "",
        down_payment: "",
        admin_fee: "",
        duration_to: "",
        profit_rate: [""],
        insurance_fee: "",
        disclaimer: { en: "", ar: "" },
        conditions: [{
            work_sectors: [],
            minimum_salary: "",
            service_period: "",
            nationality: "",
            salary_transferred_to: ""
        }],
        balloon_conditions: [],
    }

    return (
        <div className="basic_info creation_step" ref={formRef} >
            {serviceProviders &&
                <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        setLoading(true);
                        let value = { ...values, _method: "POST" }
                        if (id) { value = { ...value, _method: "PUT" } }
                        let route = !id ? `/admin/products` : `/admin/products/${id}`
                        generalCreate({
                            route: route,
                            values: { ...value },
                        }).then((res) => {
                            setLoading(false)
                            toast.success(`${t("product")} ${!id ? t("created") : t("updated")} ${t("successfully")}`)
                            if (res?.data?.data?.offer_id) {
                                toast.warning(`${t("Offer_of_id")} ${res.data.data.offer_id} ${t("has_been_deactivate")}`)
                            }
                            navigate(`/products/${res?.data?.data?.product?.id || res?.data?.data?.id}`)

                        }
                        ).catch((error: any) => {
                            console.log(error, "productcreationerror")
                            catchError(error, setLoading)
                        })
                    }}
                >
                    {(formik) => (
                        <>
                            <Form>
                                <BasicInfoForm
                                    formik={formik}
                                    productData={productData}
                                    serviceProviders={serviceProviders}
                                    t={t}
                                />
                                <ConditionsForm
                                    key={0}
                                    formik={formik}
                                    workSectors={workSectors}
                                    productData={productData}
                                />
                                <BalloonsForm
                                    key={1}
                                    formik={formik}
                                    productData={productData}
                                />
                                <div className="form_button reverse">
                                    <Button
                                        onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                        text={t("submit")} type={'submit'} disabled={loading} loading={loading}></Button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            }
        </div>
    );
}

export default CreateProductForm;