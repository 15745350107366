import { useTranslation } from "react-i18next";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import RadioInput from "../../../../components/formInputs/RadioInput";
import { ErrorMessage } from "formik";
import Checkbox from "../../../../components/formInputs/Checkbox";
import { useState } from "react";
import { Nationalities, SalaryTo } from "../../../../config/FixedData";


const ConditionsInputs = ({ formik, index, workSectors }: any) => {
    const { t, i18n } = useTranslation()
    const [all, setAll] = useState(false)

    return (
        <>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("servicePeriod")}
                    inputName={`conditions.${index}.service_period`}
                    inputPlaceholder={`${t("ex")} 21`}
                    inputError={formik?.errors?.conditions && formik?.errors?.conditions[index]?.service_period}
                    inputTouched={formik?.touched?.conditions && formik?.touched?.conditions[index]?.service_period}
                    type="number"
                    input
                    showUnit
                    unit={t("month")}
                />
                <FieldWrapper
                    title={t("minimumSalary")}
                    inputName={`conditions.${index}.minimum_salary`}
                    inputPlaceholder={`${t("ex")} 1000`}
                    inputError={formik?.errors?.conditions && formik?.errors?.conditions[index]?.minimum_salary}
                    inputTouched={formik?.touched?.conditions && formik?.touched?.conditions[index]?.minimum_salary}
                    type="number"
                    input
                    showUnit
                    unit={t("sar")}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper title={t("nationality")}>
                    <RadioInput
                        name={`conditions.${index}.nationality`}
                        onChange={(e: any) => {
                            formik.setFieldValue(`conditions.${index}.nationality`, e.target.value)
                        }}
                        value={formik?.values?.conditions[index]?.nationality}
                        key={formik?.values?.conditions[index]?.nationality}
                        data={Nationalities}
                    />
                </FieldWrapper>
            </div>
            <div className="inputs_group">
                <FieldWrapper title={t("salaryTransfer")}>
                    <RadioInput
                        name={`conditions.${index}.salary_transferred_to`}
                        onChange={(e: any) => formik.setFieldValue(`conditions.${index}.salary_transferred_to`, e.target.value)}
                        value={formik?.values?.conditions[index]?.salary_transferred_to}
                        key={formik?.values?.conditions[index]?.salary_transferred_to}
                        data={SalaryTo}
                    />
                </FieldWrapper>
            </div>
            <div className="inputs_group">
                <FieldWrapper title={t("workSectors")}>
                    <div className="checkboxes_wrapper">
                    <label>
                            <input
                                checked={all}
                                id={`all`}
                                type="checkbox"
                                value={"all"}
                                onChange={(e) => {
                                    setAll(!all)
                                    formik?.setFieldValue(`conditions.${index}.work_sectors`, !all ? workSectors.map((item: any) => item.value) : [])
                                }}
                            />
                            {t("all")}
                        </label>

                        {workSectors?.map((item: any, i: number) => (
                            <Checkbox
                                workSectors={workSectors}
                                name={`conditions.${index}.work_sectors`}
                                key={i}
                                label={item}
                                value={formik?.values?.conditions[index]?.work_sectors?.some((checkedItem: string) => checkedItem == item?.value)}
                                checked={formik?.values?.conditions[index]?.work_sectors}
                                formik={formik}
                            />
                        ))}

                  
                    </div>
                    <p className="error check"><ErrorMessage name={`conditions.${index}.work_sectors`} /></p>
                </FieldWrapper>
            </div>
        </>
    );
}

export default ConditionsInputs;