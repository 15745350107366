import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { API_FILE_URL } from '../../../config/APIs';

const BalloonTable = ({ tableHeaders, data, noDataMessage, lessColumns }: any) => {

    const navigate = useNavigate()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t } = useTranslation()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });



    return (
        <div className={`table_container ${lessColumns && isDesktop && "lessColumns"}`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass} `} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                                <div className="item clickable has_logo" key={index}>     
                                 <div className="column logo ">
                                    {/* <div>
                                        {item?.logo_url && <img src={item?.logo_url} alt="" />}
                                    </div> */}
                                       <div className='logo-container'>
                                        {<img src={`${API_FILE_URL}/${item?.vehicle_model?.vehicle_make?.logo_image_path}${item?.vehicle_model?.vehicle_make?.logo}`} alt="" />}
                                    </div>
                                    <div>
                                    {item?.vehicle_model.vehicle_make.name|| "-"}
                                    </div>
                                </div>      
                                    {/* <div className="column text-center">{item?.vehicle_model.vehicle_make.name|| "-"}</div>              */}
                                    <div className="column ">{item?.vehicle_model.name || "-"}</div>             
                                    <div className="column ">{item?.balloon_percentage || "-"}</div>             
                                    <div className="column ">{item?.salary_transferred_to || "-"}</div>             
                                </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
        </div>
    );
}

export default BalloonTable;